import React from 'react';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Head from '../../../components/head';
import Layout from '../../../components/layout';
import AddOnList from '../../../components/addonList';

export default function SingleAddOn({ data: { addon, otherAddOns } }) {
  const addons = otherAddOns.nodes;
  return (
    <Layout>
      <Head title={`Tillval: ${addon.name}`} description={addon.text} />
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page">
        <h1>
          <span aria-hidden="true" />
          Tillval: {addon.name}
        </h1>

        <div className="grid sm:grid-cols-2 sm:gap-8">
          <div>
            <p className="sm:text-lg">{addon.text}</p>
            <SanityBlockContent
              blocks={addon._rawFullText}
              renderContainerOnSingleChild
              className="sm:text-lg antialiased m-auto pb-2 sm:pb-6"
            />
          </div>
          <GatsbyImage image={addon.image?.asset.gatsbyImageData} className="w-auto border" alt={addon.name} />
        </div>
        <AddOnList addons={addons} title="Flera tillval" />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    addon: sanityAddOn(id: { eq: $id }) {
      name
      text
      _rawFullText(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          gatsbyImageData(fit: CROP, aspectRatio: 1.67, placeholder: DOMINANT_COLOR)
        }
      }
    }
    otherAddOns: allSanityAddOn(filter: { id: { ne: $id } }, sort: { fields: _updatedAt, order: DESC }) {
      nodes {
        name
        text
        _rawFullText(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            gatsbyImageData(fit: CROP, aspectRatio: 1.5, placeholder: DOMINANT_COLOR)
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
