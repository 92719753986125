import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

function SingleAddOn({ addon }) {
  return (
    <Link
      to={`/vara-losningar/tillval/${addon.slug.current}`}
      className="addon sm:hover:bg-gray-100 sm:hover:opacity-80 transition duration-300 ease-in-out border-b-2 border-transparent sm:hover:border-greenblue"
    >
      <GatsbyImage image={addon.image?.asset.gatsbyImageData} alt={addon.name} />
      <h5 className="pb-2 sm:p-2 mb-0 mt-4 text-lg sm:text-xl">{addon.name}</h5>
      <p className="sm:p-2 mb-4">{addon.text}</p>
    </Link>
  );
}

export default function AddOnList({ addons, title }) {
  return (
    <>
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page mt-12 sm:mt-20">
        <h2 className="mb-2">
          <span
            className="bg-greenblue block w-8 sm:w-12	h-0.5 sm:h-1  mb-4 rounded-sm sm:animate-fade-content-page"
            aria-hidden="true"
          />
          {title ? title : 'Tillval'}
        </h2>
        <p className="text-gray-500 mb-6 sm:mb-12">Se våra tillval</p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-6 m-auto">
        {addons.map((addon) => (
          <SingleAddOn key={addon.name} addon={addon} />
        ))}
      </div>
    </>
  );
}
